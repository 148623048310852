/*** le CSS général de l'application ***/
@tailwind base;
@tailwind components;
@tailwind utilities;


/*

couleurs

*/

.dark{ background-color: #111C2B; }
.dark-t{ color: #111C2B; }
.dark-b{ border-color: #111C2B !important; }

.grey{ background-color: #8C8C8C; }
.grey-t{ color: #8C8C8C; }
.grey-b{ border-color: #8C8C8C !important; }

.medgrey{ background-color: #CCCCCC; }
.medgrey-t{ color: #CCCCCC; }
.medgrey-b{ border-color: #CCCCCC !important; }

/* couleur de fond de l'application */
.lightwhite{ background-color: #F9FBFC; }
.lightwhite-t{ color: #F9FBFC; }
.lightwhite-b{ border-color: #F9FBFC !important; }

.white{ background-color: #ffffff; }
.white-t{ color: #ffffff; }
.white-b{ border-color: #ffffff !important; }

/* bouton */
.green{ background-color: #20CA7E; }
.green-t{ color: #20CA7E; }
.green-b{ border-color: #20CA7E !important; }

/* hover sur bouton */
.darkgreen{ background-color: #1CB06E; }
.darkgreen-t{ color: #1CB06E; }
.darkgreen-b{ border-color: #1CB06E !important; }

/* message warning */
.red{ background-color: #eb5a46; }
.red-t{ color: #eb5a46; }
.red-b{ border-color: #eb5a46 !important; }

/* orange plalette */
.orange{ background-color: #ff9500; }
.orange-t{ color: #ff9500; }
.orange-b{ border-color: #ff9500 !important; }

/* yellow palette */
.yellow{ background-color: #F2D600; }
.yellow-t{ color: #F2D600; }
.yellow-b{ border-color: #F2D600 !important; }



/* 

fonts

*/

@font-face{
  font-family: 'Open Sans';
  src: url('open-sans.ttf');
}

html, .Toastify__toast-body, select, input, button, textarea{ 
  font-family: 'Open Sans', sans-serif;
  overflow: hidden;
}
.Toastify__toast--default{
  background-color: #111C2B !important;
  color: white !important;
}
html{
  background-color: #F9FBFC;
  word-break: break-word;
  text-overflow: ellipsis;
}

/* texte classique */
html{
  font-size: 14px;
}


/* titre de page */
h1{
  margin: 8px 0px;
  font-size: 24px;
}

/* titre de carte */
h2{
  font-size: 18px;
}

/* titre de partie de carte */
h3{
  font-size: 14px;
}

hr{
  border-top: none;
  width: 100%;
  margin: 18px 0px;
  color: #F0F0F0;
}

p{
  margin: 8px 0px;
}


/*

composants génériques

*/
body{
  margin: 0px;
}
.__react_component_tooltip{
  min-width: 140px !important;
  max-width: 140px !important;
  text-align: center;
}
iframe{
  border: none;
  width: 100%;
}
a:link, a:visited{
  color: initial;
  text-decoration: none;
}
input[type="file"] {
  display: none;
}
input[type="date"]:before {
  content: attr(placeholder) !important;
  color: #111C2B;
}
input[type="date"]:focus:before,
input[type="date"]:valid:before {
  content: "";
}
input[value=""]::-webkit-datetime-edit {
  color: transparent; 
}
input[type="time"]{
  padding: 0px 8px;
  border-radius: 8px;
  border: 1px solid #b8b8b8;
  margin: 4px 0px;
  height: 42px;
}
ol{
  padding-inline-start: 10px
}

/* phone input */

.react-tel-input .form-control{
  border-radius: 8px !important;
  border: 1px solid #b8b8b8 !important;
  height: 44px !important;
}


/* slider */

.slider{
  -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 12px; /* Specified height */
  background: #e0e0e0; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;
}
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 12px; /* Set a specific slider handle width */
  height: 12px; /* Slider handle height */
  background: grey; /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 50%;
}

.slider::-moz-range-thumb {
  width: 12px; /* Set a specific slider handle width */
  height: 12px; /* Slider handle height */
  background: grey; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

/*

absolute/relative 

*/

.abs{ position: absolute; }
.rel{ position: relative; }
.fix{ position: fixed; }


/* 

grille 

*/

.flex{ 
  display: flex; 
  display: -webkit-box; 
  display: -webkit-flex;
}
.flex1{ flex: 1; }
.flex2{ flex: 2; }
.flex3{ flex: 3; }
.flex4{ flex: 4; }
.flex-wrap{ flex-wrap: wrap; }
.flex-auto{ margin:auto }
.flex-dcol{ flex-direction: column; }
.flex-jcenter{ justify-content: center; }
.flex-jsa{ justify-content: space-around; }
.flex-jsb{ justify-content: space-between; }
.flex-ace{ align-items: center; }

.width-10{ min-width : 10px; }
.width-20{ min-width : 20px; }
.width-40{ min-width : 40px; }
.width-60{ min-width : 60px; }
.width-80{ min-width : 80px; }
.width-100{ min-width : 100px; }

.height-10{ min-height : 10px; }
.height-20{ min-height : 20px; }
.height-40{ min-height : 40px; }
.height-60{ min-height : 60px; }
.height-80{ min-height : 80px; }
.height-100{ min-height : 100px; }
.height-200{ min-height : 200px; }

/* hover */
._hover{
  cursor: pointer;
}
._hover:hover{
  opacity: 0.7;
}

._hover_big{
  cursor: pointer;
  transition: transform 100ms ease-in-out;
}
._hover_big:hover{
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
  transition: transform 100ms ease-in-out;
}

._hover_drag{
  cursor: grab;
}

._hover_drag:hover{
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
  border: 1px solid #a9a9a9;
  border-radius: 8px;
  transform: scale(1.05);
}

/* contenu scrollable */
._scrollable-content{
  overflow-y : auto;
  position: relative;
}

/* slider multiple */
.multi-range-slider{
  box-shadow : none !important;
  border: none !important;
}
.multi-range-slider .bar-right, .multi-range-slider .bar-left{
  background-color: #d9d9d9 !important;
  box-shadow: none !important;
}
.multi-range-slider .bar-inner{
  box-shadow: none !important;
  border: none !important;
  background-color: #a9a9a9 !important;
}
.multi-range-slider .thumb::before{
  border: solid 1px #111C2B !important;
  box-shadow: none !important;
}
.multi-range-slider .min-value, .multi-range-slider .max-value{
  background-color: #111C2B !important;
}

/* phone */
.PhoneInputCountryIcon--border{
  box-shadow: none !important;
}
.PhoneInputCountryIconImg{
  border-radius: 8px;
  border: 1px solid #8C8C8C;
}

/** SCROLL BAR **/

/* width */
::-webkit-scrollbar {
  height: 12px;
  width: 12px;
  margin: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border: solid 2px transparent;
  box-shadow: inset 0 0 10px 10px #888;
  border-radius : 10px
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  border: solid 1px #444;
/* time-picker */
}

.react-time-picker__wrapper{
  border: 1px solid #b8b8b8 !important;
  margin-top: 4px;
  border-radius: 8px;
  height: 44px;
  padding: 0px 4px;
}
.react-time-picker__wrapper button{
  display: none;
}