/* home */
.home-welcome-modal .modal-content{
  margin-top: -10px;
}
.home-widget{
  height: 200px;
  width: 190px;
}
.home-widget-icon{
  text-align: center;
}
.home-widget-icon svg{
  font-size: 42px;
  margin-bottom: 18px;
}
.home-widget-icon img{
  width: 50%;
}
._home-account-container{
  max-height: 650px;
}
._home-account-text{
  max-width: 450px;
  text-align: center;
}
._home-account-img{
  text-align: center;
}
._home-account-container button{
  padding: 30px 60px;
  font-size: 16px;
  max-width: initial;
}
._home-account-img img{
  width: 200px;
  height: auto;
  margin: 12px;
}
._home-chevron-container{
  width: 80px;
}
._home-chevron{
  margin: auto;
  font-size: 38px;
  cursor: pointer;
}
._home-chevron:hover{
  opacity: 0.8;
}
._home-header{
  text-align: center;
  margin: 20px;
}
._home-header svg{
  font-size: 22px;
  margin: auto;
}
._home-header-value{
  margin: 10px 0px;
  font-size: 22px;
}
._home-header-value span{
  font-size: 14px;
}
._home-card-value{
  margin: 10px 0px;
  font-size: 32px;
}
._home-config{
  min-width: 700px;
  max-width: calc(100vw - 432px);
  height: calc(100vh - 68px);
  overflow-y: scroll;
  padding-top: 20px;
  padding-right: 12px;
  top: 48px;
  position: fixed;
}
._home-config-fixed{
  z-index: 9;
  background-color: #e6e6e6;
  position: fixed;
  right: 0px;
  top: 48px;
  padding: 20px 40px;
  width: 300px;
  height: calc(100vh - 88px);
  overflow-y: auto;
}
._home-config-fixed p{
  margin-bottom: 0px;
}
._home-config-step-header h1{
  margin-bottom: 18px;
}
._home-config-step-header img{
  margin: 20px;
}
._home-config .cards-items{
  text-align: center;
}
._home-config .cards-header{
  height: 12px;
}
._home-config .cards-title{
  height: 36px;
}
._home-config-next-btn{
  margin-top: 22px;
  height: 48px;
  margin-bottom: 40px;
}
._home-config-intro span{
  font-weight: bold;
  font-size: 22px;
  margin-right: 6px;
  line-height: 0px;
}
._home-config-pack{
  text-align: center;
  border: 1px solid #cccccc;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 28px;
  padding: 18px;
  margin-top: 20px;
}
._home-config-pack-inactive{
  filter: grayscale(100%);
  opacity: 0.4;
  cursor: initial;
}
._home-config-pack:hover{
  opacity: 0.8;
}
._home-config-pack-inactive:hover{
  opacity: 0.4;
}
._home-config-pack img{
  width: 100px;
  height: 100px;
  margin: auto;
}
._home-config-pack-price{
  padding: 8px 16px;
  color: white;
  background-color: #bfbfbf;
  margin: 18px -18px -18px -18px;
  border-radius: 0px 0px 6px 6px;
}
._home-config-pack-list{
  text-align: initial;
  font-size: 12px;
  padding: 0px;
}
._home-config-pack ul, ._home-config-fixed ul{
  list-style: none;
}
._home-config-pack ul li:before, ._home-config-fixed li:before{
  content: '✓';
  margin-right: 4px;
}
._home-config-pack-icon{
  text-align: center;
  font-size: 32px;
}
._home-config-contact{
  margin: 32px -6px 0px -12px;
}
._home-config-contact .button{
  text-align: initial;
}
._home-config-contact .button-primary{
  text-align: center;
  padding: 20px 40px;
  margin-bottom: 12px;
}

/** adm-account */
.adm-account-subscription p{
  margin-right: 22px;
}
.adm-account-subscription span{
  text-decoration: underline;
  cursor: pointer;
}
.adm-account-subscription span:hover{
  opacity: 0.8;
}

/* adm-attributes*/
.adm-attributes-container{
  background-color: #F0F0F0;
  border-radius: 4px;
  padding: 20px 30px;
  margin-top: -18px;
}
.adm-attributes-search .text-input{
  margin-bottom: 0px;
}

/* adm-admin */
.adm-admin-level-1 .context-menu{
  right: 212px !important;
}

/* adm-filter */
.adm-filter-delete svg{
  margin: auto 10px;
  cursor: pointer;
}
.adm-filter-delete svg:hover{
  opacity: 0.8;
}
.adm-filter-icon{
  margin: auto 46px auto 0px;
}

/** adm lucca */
.adm-lucca-filter-team{
  margin-top: 42px;
  width: 220px;
}

/* adm roles */
.adm-roles-card{
  padding: 20px 30px;
  overflow-x: auto;
  height: calc(100% - 40px);
}
.adm-roles-direction{
  margin-right: 42px;
}

/* adm mapping */
.adm-mapping-icon{
  padding-right: 28px;
}
.adm-mapping-sample{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 200px;
}
.adm-mapping-merge{
  cursor: pointer;
  text-decoration: underline;
}
.adm-mapping-saving-icon{
  width: 62px;
  text-align: center;
}
.adm-mapping-saving-icon svg{
  margin: auto;
  font-size: 22px;
}
.adm-mapping-saving-text{
  margin: 12px 0px;
}
.adm-mapping-filter-available{
  margin: 0px 26px 32px 26px;
}
.adm-mapping-filters-input{
  margin-right: 42px;
}
.adm-mapping-filters-input .text-input-context{
  width: calc(100% - 10px) !important;
}
.adm-mapping-errors{
  margin-top: 12px;
  max-height: 200px;
  overflow-y: auto;
}
.adm-mapping-errors p{
  margin: 4px 0px;
}

/* adm-merge */
.adm-merge-attributes{
  margin-left: 88px;
}

/* upload liste utilisateur */
.adm-upload-icon{
  color: #CCCCCC;
}
.adm-upload-icon svg{
  margin: auto;
  font-size: 112px;
  padding: 26px;
}

/* adm-user : formulaire de modification utilisateur */
.adm-user h3{
  margin-bottom: 22px;
}
.adm-user .text-input{
  margin-right: 22px;
}
.adm-user-attributes .text-input{
  width: 30%;
}
.adm-user-image{
  position: absolute;
  right: 40px;
  top: 30px;
}
.adm-user-image img{
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

/* formulaire pour demande compte */
.ask-template-button .button{
  margin: 0px;
  padding: 30px;
  width: calc(100% - 22px);
}

/* dashboard */
.dashboard-attributes-fullscreen{
  width: 100vw;
  height: calc(100vh + 48px);
  position: fixed;
  background-color: white;
  top: -48px;
  right: 0px;
  z-index: 9;
}

/* edit-question-modal */
.edit-question-background{
  border-radius: 8px;
  padding: 0px 10px 0px 26px;
  margin: -52px -22px -0px -22px;
}

/** login trial */
.login-trial-content .card{
  width: 600px;
}
.login-trial-content img{
  width: 100%;
}
.login-trial-form{
  padding-left: 20px;
  margin: 20px 0px;
}

/** Onboarding intro*/
.onboarding-video-iframe{
  width: 672px;
  height: 378px;
  z-index: 9;
  border-radius: 8px;
}
.learning-iframe{
  width: calc(100vw - 110px) !important;
}
.onboarding-video{
  position: absolute;
  bottom: 20px;
  right: 30px;
}
.onboarding-video-icon{
  color: white;
  position: absolute;
  font-size: 36px;
  bottom: 2px;
  right: 6px;
}
.onboarding-intro h1{
  text-align: center;
  font-size: 32px;
}
.onboarding-intro .card{
  position: relative;
  width: 900px;
  height: 600px;
}
.onboarding-tab{
  text-align: center;
  padding: 16px 6px;
  min-width: 140px;
}
.onboarding-tab-title{
  font-size: 18px;
  padding-bottom: 6px;
  font-weight: 600;
}
.onboarding-tab-inactive{
  border-bottom: 1px solid;
  background-color: #e9e9e9;
}
.onboarding-step{
  margin: auto;
  position: relative;
}
.onboarding-step-close{
  position: fixed;
  bottom: 14px;
  right: 14px;
}
.onboarding-step-card{
  width: 360px;
}
.onboarding-step-card .card{
  border : 1px solid #8C8C8C
}
.onboarding-step-card-body{
  font-size: 22px;
}
.onboarding-step-arrow{
  margin-right: 0px !important;
  margin-left: 12px;
}
.onboarding-step img{
  border-radius: 8px;
}
.onboarding-step-card img{
  width: 100px;
  height: 100px;
  position: absolute;
  filter: opacity(0.7);
}
.onboarding-step-back .button{
  padding-left: 0px;
  margin-left: 0px;
}
.onboarding-card{
  position: absolute;
  border-radius: 8px;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 428px;
}
.onboarding-card img{
  width: 100%;
  border-radius: 0px 8px 0px 0px;
}
.onboarding-login{
  height: 100vh;
  width: 100vw;
  position: relative;
}
.onboarding-login-account .text-input{
  min-width: 230px;
}
.onboarding-login-user .text-input{
  max-width: 210px;
}
.onboarding-login-header{
  padding: 20px 40px;
  position: fixed;
  width: calc(100% - 80px);
  z-index: 9;
}
.onboarding-login-container{
  height: 100%;
  min-width: 1000px;
  max-width: 1200px;
  position: relative;
}
.onboarding-login-background{
  position: absolute;
  top: 0px;
  right: 0px;
  width:100%;
  z-index: -1;
}
.onboarding-login-contact{
  background-color: white;
  border-radius: 20px;
  border : 2px solid white;
}
.onboarding-login-contact img{
  height: 40px;
  width: 40px;
  margin-right: 12px;
}
.onboarding-login-contact span{
  margin: auto;
  margin-right: 18px;
}
.onboarding-login-end p{
  color: white;
  font-size: 32px;
  text-align: center;
  line-height: 64px;
  padding: 18px;
  margin: 56px 18px;
}
.onboarding-end-item{
  padding: 6px 0px;
}
.onboarding-end-item svg{
  margin-right: 6px;
}
.onboarding-form h1{
  font-size: 32px;
  margin-bottom: 22px;
}
.onboarding-form{
  margin-bottom: 40px;
  margin-left: 120px;
  min-height : 526px;
}
.onboarding-form h2{
  font-weight: initial;
  margin: auto 22px;
  font-size: 22px;
}
.onboarding-login-image{
  position: fixed;
  bottom: calc(-68vh / 3);
  right: calc(-68vh / 2);
}
.onboarding-login-image img{
  height: 68vh;
  border-radius: 8px;
  transform : rotate(-8deg)
}
@media screen and (max-height: 600px){
  .onboarding-login-image {
    display: none;
  }
}
@media screen and (max-width: 1000px){
  .onboarding-login-image {
    display: none;
  }
}

/* preview filters */
.preview-filter img{
  width: 100%;
  opacity: 0.5;
  border-radius: 8px;
}

/* project */
.project-invitation-body{
  background-color : white;
  top: 300px;
  width: 432px;
  left: 68px;
  height: 178px;
  overflow: hidden;
}
.project-invitation-body p{
  font-size: 10px;
  text-align: justify;
}
.project-calendar-chip{
  margin: 4px 0px;
  padding : 4px 0px;
  cursor : grab 
}
.project-calendar-chip:hover{
  transform: scale(1.05);
}
.project-calendar-chip svg{
  margin: auto 8px;
}

/* Synthèse dashboard responsive */
.results-responsive{
  margin-left : 10px;
  max-width : 1000px;
  left : -50px;
  display : block;
}

/* inviter toutes les équipes */
.segment-context-link{
  font-size: 12px;
  padding-top: 1px;
  text-decoration: underline;
  cursor: pointer;
}
.segment-context-link:hover{
  opacity: 0.8;
}
.segment-context-filter-action{
  cursor: pointer;
}
.segment-context-filter-action:hover{
  opacity: 0.8;
}

/* search-question */ 
.search-question-container{
  margin: 0px -40px;
}
.search-question{
  width: 828px;
  height : 400px;
  overflow-y : auto;
  border-bottom: 1px solid #b8b8b8;
  border-top: 1px solid #b8b8b8;
}
.search-question-secondary{
  width: 60px;
  text-align: center;
}

/* email-invitation */
.survey-email{
  font-family: Arial, Helvetica, sans-serif;
  border: 1px solid;
}
.survey-email p {
  margin-bottom: 8px;
  line-height: 22px;
}
.survey-email-header{
  height: 30px;
  color: white;
}
.survey-email-header span{
  margin: auto;
}
.survey-email-options{
  border-bottom: 1px solid;
  padding: 10px 20px;
}
.survey-email-titlefat{
  text-align: center;
  font-size: 18px;
  margin: 22px;
  margin-top: 32px;
}
.survey-email-content textarea{
  resize: none;
  border: none;
  padding: 6px 10px;
  background-color: #eaeaea;
  border-radius: 8px;
  width: calc(100% - 20px);
}
.survey-email-edit-block{
  margin-left: 6px;
  cursor: pointer;
}
.survey-email-edit-block:hover{
  opacity: 0.8;
}
.survey-email-content{
  padding: 10px 20px;
  margin: 0px 20px;
  background-color: white;
}
.survey-email-content p{
  white-space: pre-line
}
.survey-email-button{
  margin: 12px auto;
  border: 1px solid;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
}
.survey-email-link{
  font-size: 10px;
  text-align: center;
}

/* intro à l'enquête */
.survey-intro-header{
  width: 600px;
  text-align : center;
  margin: auto;
}
.survey-intro-div{
  margin: 120px 60px 30px 60px;
  min-height: 220px; /* safari */
}
.survey-intro-div img{
  height: 180px;
  width: auto;
  margin: auto;
}
.survey-intro-part{
  width: 42px;
  height: 42px;
  border-radius: 50%;
  text-align: center;
  font-weight: bold;
  border: 1px solid;
  margin: auto 30px;
  font-size: 28px;
  line-height: 40px;
}
.survey-intro-div-text p{
  width: 240px;
}

/* modale signup */
.signup-rgpd-link{
  cursor: pointer;
  text-decoration: underline;
  font-size: 12px;
  margin-bottom: 18px;
}
.signup-rgpd-link:hover{
  opacity: 0.8;
}
.signup-feature{
  background-color: #e6e6e6;
  text-align: center;
  padding: 20px;
  margin: -30px 20px -50px -40px;
}
.signup-form{
  width: 300px;
  margin: auto;
}
.signup-form h1{
  text-align: center;
}
.signup-feature img{
  width: 360px;
}
.signup-email-form .text-input{
  padding-right: 0px;
  margin-bottom: 12px;
}
.signup-email-form .button{
  margin: 0px;
}
.signup-email-legal{
  margin-top: 22px;
}
.signup-email-legal p{
  font-size: 12px;
  margin-bottom: 12px;
}

/* super-users */
.super-users-stars span{
  margin: 0px 6px;
}
.super-users-stars svg{
  margin-right: 0px;
}

/* overview enqupete */
.survey-overview-status{
  text-align: center;
}
.survey-overview-status svg{
  margin-bottom: 18px;
  font-size: 56px;
}
.survey-overview-status span{
  font-weight: bold;
}
.survey-overview-link{
  cursor: pointer;
  text-decoration: underline;
  margin: 12px 0px;
}
.survey-overview-link:hover{
  opacity: 0.8;
}
.survey-overview-warning{
  margin: auto;
  margin-right: 18px;
}

/* page preview */
.survey-preview-header{
  padding : 20px 0px 0px 252px;
}
.survey-preview-header .page-header{
  width: 688px;
}
.survey-preview-content{
  margin-left: 22px;
}

/* programmer date des relances */
.survey-program-relaunch-text{
  margin: auto 12px;
}
.survey-program-delete{
  margin-top: 40px;
  margin-left: 12px;
  cursor: pointer;
}

/* page mon arbre */
.survey-self-formations{
  margin: -110px 0px;
  padding: 40px;
  min-width: 360px;
  max-width: 360px;
}
.survey-self-formations-title{
  border-bottom: 1px solid #F0F0F0;
  margin: 0px -30px;
  padding: 0px 30px;
  padding-bottom: 20px;
  margin-bottom: 12px;
}
.survey-self-formations-title svg{
  font-size: 18px;
  margin: 0px 6px -2px 0px;
}
.survey-self-formations-image{
  position: relative;
}
.survey-self-formations-image img{
  width: 100%;
  height: auto;
  border-radius: 4px;
  margin: 12px 0px;
  cursor: pointer;
}
.survey-self-formations-image img:hover{
  opacity: 0.8;
}
.survey-self-formations-duration{
  color: white;
  position: absolute;
  z-index: 9;
  padding: 4px 8px;
  border-radius: 4px;
  bottom: 24px;
  right: 8px;
}

/* sélectionner */
.survey-segment-line{
  margin-top: 12px;
  position: relative;
}
.survey-segment-line .text-input-context{
  width: 300px;
  margin-top: 40px;
}
.survey-segment-icon{
  font-size: 32px; 
  padding-top: 28px;
  margin-bottom: 38px;
  width: 60px;
}
.survey-segment-icon svg{
  margin: auto;
}
.survey-segment-icon img{
  height: 60px;
  width: 60px;
  margin: -10px 0px;
}
.survey-segment-text{
  padding: 40px 6px;
}

/* survey-start */
.survey-start-check-image{
  width: 80px;
  height: 80px;
  margin-top: 14px;
  margin-right: 36px;
  margin-bottom: 62px;
}

/* survey results */
.survey-results-compare{
  margin : 0px 22px;
  border-radius: 4px;
  border: 2px dotted;
  padding: 40px;
  text-align: center;
}

/* survey-rps */
.survey-rps-field-header{
  font-size: 12px;
  margin-bottom: 10px;
}
.survey-rps-field-header-details{
  padding-top: 16px;
  cursor: pointer;
}
.survey-rps-field-header-details:hover{
  background-color: #F9FBFC;
}
.survey-rps-field{
  text-align: center;
  margin: auto;
}
.survey-rps-user-icon{
  font-size: 24px;
  margin: -6px 0px;
  margin-right: 12px;
}
.survey-rps-axis-reverse-container{
  min-width : 30px;
  position: relative;
}
.survey-rps-axis-reverse{
  transform: rotate(-90deg);
  transform-origin: 0 100%;
  position: absolute;
  height: 30px;
  min-width: 60px;
  width: 100%;
  left: 30px;
}
.survey-rps-axis{
  position: absolute;
  width: calc(100% - 208px);
  right: 38px;
  bottom: 30px;
}
.survey-rps-axis-info{
  cursor: pointer;
}
.survey-rps-axis-info:hover{
  opacity: 0.8;
}
.survey-rps-graph-dot{
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin-right: 5px;
}
.survey-rps-bottom{
  margin-top: 10px;
  height: 16px;
  border-bottom: 1px solid;
  border-right: 1px solid;
  border-left: 1px solid;
}
.survey-rps-bottom-text{
  text-align: center;
  font-size: 12px;
  margin-top: 10px;
}

/* survey-users-speed*/
.survey-users-add{
  font-size: 64px;
  margin: auto;
  cursor: pointer;
  top: calc(50% - 50px);
  left: -32px;
  background-color: white;
}
.survey-users-img img{
  width: 320px;
  height: auto;
  margin: 40px auto;
}
.survey-users-list{
  overflow-y: auto;
  margin : 34px -30px 0 -30px;
  max-height: 340px;
}

/** voir les questions */
.survey-viewer-separator-left{
  margin: -92px 60px -80px 140px;
  border-left: 2px dotted white;
}
.survey-viewer-cta{
  position: fixed;
  bottom: 0px;
}
.survey-viewer-separator-right{
  position: absolute;
  border-left: 2px dotted white;
  margin-left: calc(50% - 22px);
  height: 172px;
  margin-top: -92px;
}
.survey-viewer-separator-middle{
  position: absolute;
  border-left: 2px dotted white;
  margin-left: calc(50% - 148px);
  height: 134px;
  margin-top: -54px;
}
.survey-viewer-separator-middle-left{
  position: absolute;
  border-left: 2px dotted white;
  margin-left: calc(50% - 256px);
  height: 134px;
  margin-top: -54px;
}
.survey-viewer-card{
  width: 270px;
  margin-right: 22px;
}
.survey-viewer-trial-checklist{
  margin-left: -40px;
  margin-top: -20px;
  margin-right: 40px;
}
.survey-viewer-trial-content{
  min-width: 600px;
  max-width: 800px;
}
.survey-viewer-card-trial{
  margin-bottom: 42px;
}
.survey-viewer-edit-btn{
  margin-left: 6px;
  cursor: pointer;
}
.survey-viewer-edit-btn:hover{
  opacity: 0.8;
}

/* visionneuse des questionnaires */
.survey-viewer-container{
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 48px);
  max-width: calc(100vw - 300px);
  min-width: calc(100vw - 300px);
  bottom: 0px;
  position: fixed;
  right: 0px;
}
.survey-viewer-floating{
  position: fixed;
  right: 52px;
  top: 82px;
}
.survey-viewer-floating .button{
  border: 1px solid #ffffff61;
  color: white;
  margin: 5px;
  margin-bottom: 14px;
}
.survey-viewer-floating .button-primary{
  border: none;
  margin: 4px;
  margin-bottom: 14px;
}
.survey-viewer-floating .button-classic:not(.button-primary):hover{
  background-color: #ffffff33;
}
.survey-viewer-side-title{
  text-align: center;
  color: #8C8C8C;
  margin: 20px 0px;
}
.survey-viewer-side-btn{
  position: relative;
  border-radius: 4px;
  font-size: 16px;
  color : white;
  padding : 4px 12px 10px 12px;
  cursor: pointer;
  margin-bottom: 12px;
  border: 1px solid;
}
.survey-viewer-side-btn .select-help{
  position: absolute;
  top: 4px;
  right: -4px;
}
.survey-viewer-side-btn:hover{
  opacity: 0.8;
}
.survey-viewer-side-btn span{
  font-size: 12px;
}
.survey-viewer-chevron{
  z-index: 1;
  position: fixed;
  margin-top: 280px;
  text-align: center;
  color: white;
  cursor: pointer;
  width: 90px;
  padding : 12px;
}
.survey-viewer-chevron:hover{
  color: #e4e4e4;
}
.survey-viewer-chevron svg{
  font-size: 22px;
}
.survey-viewer-chevron-label{
  margin-top : 12px;
}
.survey-viewer-template{
  color: white;
  border: 1px solid white;
  border-radius: 4px;
  padding: 12px 18px;
  margin: 20px 0px 10px 0px;
  position: relative;
  margin: 20px 0px 10px auto;
  position: relative;
  width: 284px;
}
.survey-viewer-sidemenu{
  width: 188px;
  padding : 20px;
  padding-left: 10px;
  padding-top: 16px;
}
.survey-viewer-helper{
  margin-top: 12px;
  font-size: 12px;
}

/* user picture filter */
.user-filter-picture{
  height: 20px;
  border-radius: 10px;
}