._none{
  display: none
}

._animated{
  background: linear-gradient(
    150deg,
    #ff4800 13.4%,
    #da54d8 50%,
    #0092f4 86.6%
  );
  background-size: 200% 200%;
  -webkit-animation: ListIconAnimation 2s ease infinite;
  -moz-animation: ListIconAnimation 2s ease infinite;
  -o-animation: ListIconAnimation 2s ease infinite;
  animation: ListIconAnimation 2s ease infinite;

  /*
  For Text you need to add these 2 style props in JSX :
    background-clip: text;
    color: transparent;
  */
}
@-webkit-keyframes ListIconAnimation {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@-moz-keyframes ListIconAnimation {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@-o-keyframes ListIconAnimation {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@keyframes ListIconAnimation {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}

._vertical-shake{
  animation: verticalShake 1s ease 0s 1 normal none;
}
@keyframes verticalShake {
  0%,100% {
    transform: translateY(0);
  }
  10%,30%,50%,70% {
    transform: translateY(-4px);
  }
  20%,40%,60% {
    transform: translateY(4px);
  }
  80% {
    transform: translateY(2px);
  }
  90% {
    transform: translateY(-2px);
  }
  
}

/* bouton */
.button{
  margin: 4px;
  border-radius: 8px;
  border: none;
  max-width: 200px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 10px 20px;
  outline:none;
  cursor: pointer;
  background-color: initial;
}
.button:hover{
  opacity: 0.8;
}
.button svg{
  margin-right: 6px;
}
.button-large{    
  min-width: 300px;
  padding: 20px 40px;
  font-size: 18px;
}

.button>.tooltip{
  text-transform: initial
}

/* carte */
.card{
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 24px rgba(0,0,0,0.1);
  margin-bottom: 28px;
  position: relative;
}
.card-options{
  position: absolute;
  right: 10px;
}
.card-title{
  font-size: 18px;
  margin-bottom: 18px;
  font-weight: bold;
}
.card .page-empty{
  margin: 20px auto;
}
.card .page-empty img{
  width: auto;
  height: 120px;
  margin: 12px;
}
.card .page-empty-button .button{
  margin: 22px 0px;
  padding: 16px 20px;
  min-width: 300px;
}
.page-empty-button{
  margin-bottom: 22px;
}

/* bouton carte */
.card-button{
  border-radius: 8px;
  background-color: #fff;
  margin: 14px;
  width: 140px;
  height: 140px;
  cursor: pointer;
  border: 1px solid #cccccc;
  position: relative;
  text-align: center;
  padding: 10px;
}
.card-button img{
  height: 50px;
  width: 50px;
  margin: auto;
  margin-bottom: 6px;
}
.card-button-icon{
  font-size: 42px;
}
.card-button-subtitle{
  margin-top: 6px;
  font-size: 12px;
}
.card-button-footer{
  background-color: #cccccc;
  color: white;
  margin: -10px;
  margin-top: 6px;
  padding: 6px;
  font-weight: bold;
  border-radius: 0px 0px 6px 6px;
}
.card-button:hover{
  opacity: 0.8;
}
.card-button-actions{
  position: absolute;
  top: 0px;
  right: 0px;
}
.card-button-actions .list-button{
  padding: 6px 0px;
  width: 30px;
  margin: 0px;
}

/* card-container */
.card-container{
  margin-left: -14px;
}

/* card-magic */
.card-magic{
  text-align: center;
  border-radius: 8px;
  width: 140px;
  height: 100%;
}
.card-magic img{
  width: 100px;
  height: 100px;
}
.card-magic-title{
  border: 1px solid;
  margin: 4px;
  border-radius: 4px;
  padding: 2px 0px;
  font-size: 12px;
}
.card-magic-description{
  font-size: 12px;
  padding: 8px;
}

/* cartes */
.cards{
  margin-bottom: 28px;
  margin-left: -14px;
}
.cards-card{
  border-radius: 8px;
  background-color: white;
  margin-bottom: 0px;
  margin: 14px;
  width: 240px;
  height: 240px;
  cursor: pointer;
  border: 1px solid;
  position: relative;
}
.cards-sm{
  width: 160px;
  height: 160px;
}
.cards-card img{
  margin: auto;
  height: 100px;
  width: 100px;
  border-radius: 4px;
}
.cards-card svg{
  margin: auto;
  font-size: 62px;
  color: #8C8C8C;
}
.cards-header{
  padding: 12px 24px;
  height: 18px;
}
.cards-title{
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  padding: 0px 12px;
  margin-bottom: 12px;
  margin-bottom: 8px;
  height: 62px;
}
.cards-sm img{
  height: 50px;
  width: 50px;
}
.cards-sm .cards-header{
  font-size: 12px;
  text-align: center;
}
.cards-sm svg{
  font-size: 42px;
}
.cards-sm .cards-title{
  font-size: 14px;
  height: 56px;
}
.cards-inactive{
  opacity: 0.3;
}
.cards-cta{
  border-color: #51E899 !important;
  background-color: #51E899;
  color: white;
}
.cards-cta svg{
  color: white;
}
.cards-cta .cards-header{
  height: 0px;
}
.cards-cta img{
  height: 140px;
  width: 140px;
}
.cards-inactive img{
  filter: grayscale(100%);
}
.cards-items{
  padding: 8px 16px;
  margin-bottom: -12px;
  border-radius: 0px 0px 6px 6px;
}
.cards-paying{
  position: absolute;
}
.cards-paying img{
  width: 86px;
  height: 86px;
  opacity: 0.5;
}
.cards-paying .cards-paying-text{
  transform: rotate(-45deg);
  width: 80px;
  text-align: center;
  font-size: 12px;
  top: 20px;
  left: -8px;
  line-height: 12px;
  position: absolute;
  color: white;
}

/* catégorie */
.category{
  margin: auto;
  background-color: white;
  font-weight: 600;
  padding: 2px 10px;
  color : #8C8C8C;
  border: 1px solid #8C8C8C;
  border-radius: 12px;
  cursor: pointer;
}
.category-attributes{
  margin: 4px -4px;
}
.category-attributes .category{
  margin: 4px;
}
.category:hover{
  opacity: 0.8;
}
.category svg{
  margin: 2px 4px 0px 8px;
}
.category-edit{
  width: 30px;
  margin: 0px 2px;
  border-radius: 4px;
}
.category-edit:hover{
  background-color: #dadada;
}

/* checkbox */
.checkbox-container{
  height: 46px;
  margin: auto;
}
.checkbox-inactive{
  opacity: 0.5;
}
.checkbox-inactive .checkbox{
  cursor: initial;
}
.checkbox{
  width: 22px;
  min-width: 22px;
  height: 22px;
  min-height: 22px;
  margin: auto;
  border-radius: 8px;
  border: 2px solid;
  border-color: #8C8C8C;
  cursor: pointer;
  background-color: white;
}
.checkbox:hover{
  opacity: 0.8;
}
.checkbox-action .button{
  margin: -2px;
  margin-bottom: -4px;
}
.checkbox svg{
  color: white;
  margin: auto;
}
.checkbox-active{
  border-color: #111C2B;
  background-color: #111C2B;
}
.checkbox-label{
  margin: auto 12px
}
.checkbox-superadmin-filter{
  position: absolute;
  width: 400px;
  right: 30px;
  top: 22px;
}

/** checklist */
.checklist{
  padding: 30px 40px;
  padding-right: 20px;
  max-width: 232px;
}
.checklist p{
  font-size: 12px;
}
.checklist-loading{
  margin: 40px 0px;
}
.checklist-item svg{
  font-size: 20px;
  color: #dadada;
  margin: 8px 8px 0px 0px;
}
.checklist-item-btn{
  cursor: pointer;
}
.checklist-item-btn:hover{
  opacity: 0.8;
}

/* chips **/
.chip{
  text-align: center;
  margin: -3px 0px;
  background-color: #fff;
  padding: 2px 14px;
  color: #8c8c8c;
  border: 1px solid #8c8c8c;
  border-radius: 16px;
  font-weight: 600;
  white-space: nowrap;
}
.chip-info{
  margin-right: -4px;
}
.chip-user{
  margin: 0px 6px;
}
.chip-user svg{
  margin: 0px 4px;
}
.chip-delete svg{
  margin-left: 6px;
  margin-bottom: -1px;
}
.chip-delete{
  cursor: pointer;
}
.chip-delete:hover{
  opacity: 0.8;
}
.chip-margin{
  margin: 4px;
}
.chip-editable{
  cursor: pointer;
}
.chip-editable:hover{
  opacity: 0.8;
}

.margin-chip > div{
  margin-right : 4px;
}

/* clickable element */
.clickable{
  cursor: pointer;
}

/* prendre couleur */
.color-picker-custom .context-menu{
  width: 460px;
}
.color-picker{
  height: 90px;
  margin-right: 42px;
}
.color-picker .text-input{
  width: 150px;
}
.color-picker-item{
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin: 6px;
  cursor: pointer;
  text-align: center;
}
.color-picker-context-title{
  margin: 8px 26px;
}
.color-picker-item-container .color-picker-item:hover{
  width: 30px;
  height: 30px;
  margin: 4px;
}
.color-picker-item-white{
  border: 1px solid #8C8C8C;
  width: 24px;
  height: 24px;
}
.color-picker-item-container .color-picker-item-white:hover{
  width: 28px;
  height: 28px;
  margin: 4px;
}
.color-picker-item-white svg{
  color : #8C8C8C !important
}
.color-picker-item svg{
  font-size: 14px;
  color: white;
  margin: auto !important;
}
.color-picker-title{
  margin-bottom: 12px;
}
.color-picker-selector{
  margin: 0px 12px;
}
.color-picker-selector svg{
  font-size: 14px;
  margin: auto 4px;
  cursor: pointer;
}
.color-picker-item-container{
  padding: 6px 20px;
}

/* comparateur */
.comparator{
  min-width: 872px;
  margin-right: 14px;
}
.comparator-item{
  width: 200px;
  padding: 12px 18px;
  margin: 2px 16px;
  text-align: center;
}
.comparator-text{
  background-color: white;
}
.comparator-entry{
  text-align: right;
  margin: auto 0px;
}
.comparator-info{
  text-align: initial;
  margin-bottom: 18px;
}

/* menu contexte */
.context-menu{
  width: 272px;
  background-color: white;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.20);
  padding: 14px 0px;
  z-index: 10;
  border-radius: 8px;
}
.context-menu-footer{
  padding: 10px 30px;
  margin-top: 10px;
}
.context-menu .search-bar{
  padding: 0px 20px; 
}
.context-menu-scrollable{
  border: 1px solid #F0F0F0;
  overflow-y: auto;
}
.context-menu .list-item{
  border-bottom: none;
}
.context-menu .list-item .button{
  padding: 0px;
}
.context-menu-item{
  padding: 10px 30px;
  width: calc(100% - 60px);
  cursor: pointer;
}
.context-menu-item:hover{
  background-color: #F0F0F0;
}
.context-menu-item img{
  width: 25px;
  height: 25px;
  margin-right: 8px; 
  border-radius: 50%;
}
.context-menu-item svg{
  font-size: 10px;
}
.context-menu-search{
  padding: 0px 14px;
  position: relative;
}
.context-menu-search svg{
  position: absolute;
  top: 14px;
  right: 14px;
}
.context-menu-search input{
  min-width: initial !important;
}
.context-menu .button{
  margin: initial !important;
}


/* bouton carte */
.dashboard-button{
  border-radius: 8px;
  background-color: #fff;
  margin: 0px;
  width: 110px;
  height: 110px;
  cursor: pointer;
  border: 1px solid #f0e1e1;
  position: relative;
  text-align: center;
  position: relative;
  font-size: 12px;
}
.dashboard-button-icon{
  font-size: 26px;
  position: relative;
  margin-bottom: 10px;
}
.dashboard-button-count{
  position: absolute;
  border: 1px solid;
  width: 22px;
  height: 22px;
  font-size: 12px;
  border-radius: 50%;
  top: -8px;
  right: 26px;
}
.dashboard-button-count span{
  margin: auto;
}
.dashboard-button:hover{
  opacity: 0.8;
}

/* dashboard-number */
.dashboard-number{
  font-size: 12px;
  text-align: center;
  margin: 22px 0px;
}
.dashboard-number-info svg{
  cursor: pointer;
}
.dashboard-number-info svg:hover{
  opacity: 0.8;
}
.dashboard-number-count{
  font-size: 18px;
  margin-top: 8px;
}
.dashboard-number-count svg{
  margin-right: 6px;
}

/* dropdown */
.dropdown{
  background-color: white;
  padding: 11px 14px;
  min-height: 20px;
  border-radius: 8px;
  border : 1px solid #b8b8b8;
  margin: 4px 0px;
  width: 180px;
  user-select: none;
  cursor: pointer;
  font-weight: 600;
}
.dropdown-header .chip{
  margin-top: 8px;
  margin-left: 6px;
  margin-right: 6px;
}
.dropdown-header svg{
  margin-top: 14px;
  cursor: pointer;
}
.dropdown-header svg:hover{
  opacity: 0.8;
}
.dropdown svg{
  margin: auto;
  margin-left: 6px;
  font-size: 18px;
}
.dropdown:hover{
  opacity: 0.8;
}
.dropdown-item-active svg{
  margin: auto;
  margin-right: 12px;
}
.dropdown-white{
  background-color: initial;
  border-color: white;
  color: white;
}
.dropdown-disabled{
  opacity: 0.5;
  cursor: initial;
}
.dropdown-disabled:hover{
  opacity: 0.5;
}
.dropdown-add{
  margin-right: 12px;
  margin-top: 12px;
}

/* editbar */
.editbar-button{
  border: 1px solid;
  margin: 4px;
  min-width: 64px;
  min-height: 64px;
  text-align: center;
  border-radius: 8px;
  padding: 4px;
}
.editbar-button p{
  font-size: 12px;
  margin-bottom: 0px;
}
.editbar-button svg{
  font-size: 24px;
}
.editbar-button-sm{
  min-width: 22px;
  min-height: 22px;
}
.editbar-button-sm svg{
  font-size: 18px;
}

/* generic-error-message */
.error-message{
  height: 80px;
  position: fixed;
  bottom: 0px;
  background-color: #fbcdc6;
  width: calc(100% - 80px);
  z-index: 999999999;
  padding: 0px 40px;
}

/* floating-menu */
.floating-menu{
  position: fixed;
  z-index: 9;
  padding: 20px 30px;
  max-width: 360px;
}
.floating-menu-help{
  color: white;
  max-width: 210px;
  font-size: 12px;
  margin-bottom: 12px;
}
@media screen and (max-width: 750px) {
  .floating-menu {
    display: none;
  }
}
@media screen and (max-width: 1100px) {
  .floating-menu-help {
    display: none;
  }
}

/* enter-code */
.enter-code input{
  box-shadow: none !important;
  font-family: inherit !important;
  font-size: 24px !important;
  text-align: center;
  padding-left: initial !important;
}

/* image-selector */
.image-selector img{
  border: 2px solid white;
  border-radius: 8px;
}
.image-selector-check{
  bottom: 0px;
  left: 0px;
  border-radius: 0px 8px 0px 8px;
  width: 30px;
  height: 30px;
}

/* importer image */
.import-img{
  margin: 0px 30px;
  text-align: center;
}
.import-img-circle{
  border-radius: 50%;
  height: 72px;
  margin: 6px auto;
  cursor: pointer;
  background-color: #F0F0F0;
}

/* learning item */
.learning-item{
  min-width : 174px;
  max-width : 174px;
  word-wrap: break-word;
  border-radius: 8px;
  box-shadow: 0px 4px 24px #0000001a;
  position: relative;
  background-color : #e9e9e9;
  height: 100px; 
  padding : 10px;
  margin-right: 20px;
  margin-top: 20px;
}

.learning-item-edit{
  padding: 6px 8px;
  color: white;
  background-color: #aaaaaa;
  position: absolute;
  z-index: 2;
  right: 0;
  border-radius: 8px;
}

/* lesson item*/

.lesson-item{
  word-wrap: break-word;
  margin-right: 20px;
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 24px #0000001a;
  height: 140px;
  max-width: 200px;
  min-width: 200px;
  position: relative;
}

.lesson-item-index{
  padding: 6px;
  top: -4px;
  left: -4px;
  border-radius: 50%;
  color: white;
  position:absolute;
  z-Index:1
}

.lesson-item-edit{
  padding: 6px 8px;
  color: white;
  background-color: #aaaaaa;
  position: absolute;
  z-index: 2;
  right: 0;
  border-radius: 8px;
}

.lesson-item-img{
  min-width: 100%;
  height: 90px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  object-fit: cover;
}

.lesson-item-label{
  margin: 12px 18px 0px 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  z-index: 2;
  /*bottom: 0;
  float: bottom*/
  
}

.lesson-item-locked{
  background-color: #70707070;
  cursor: not-allowed
}

/* lien */
.link{
  margin: 10px;
  text-decoration: underline;
  cursor: pointer;
}
.link svg{
  margin-right: 6px;
}
.link:hover{
  opacity: 0.8;
}

/* list-button */
.list-button{
  border-radius : 8px;
  border : none;
  outline : none;
  cursor: pointer;
  background-color: initial;
  width: 42px;
  font-size: 16px;
  margin: -8px 4px;
  padding: 4px 0px;
}
.list-button-inactive{
  color: #d8d8d8 !important
}
.list-button:hover{
  background-color: #e0e0e0;
}
.list-button-dot{
  height: 12px;
  width: 12px;
  border-radius: 50%;
  right: 8px;
  top: -8px;
  border: 2px solid white;
}

/* list-cell */
.list-cell{
  margin-right: 12px;
}

.list-cell-text{
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-cell-title{
  white-space: nowrap;
  width : 100%;
}
.list-cell-title svg{
  margin-left: 6px;
  cursor: pointer;
}
.list-cell-title svg:hover{
  opacity: 0.8;
}


/*list-container*/
.list-container-placeholder{
  padding: 0px 30px;
  margin-bottom: 40px;
}
.list-container .page-loader{
  display: inline-block;
  min-width: 100%;
}
.list-container-outline{
  position: relative;
}
.list-container-background{
  top: 0px;
  left: 0px;
  position: fixed;
  z-index: 1;
  background-color: #00000024;
  height: 100vh;
  width: 100vw;
}
.list-container-editable{
  z-index: 2;
  background-color: white;
  position: absolute;
  top: 0px;
}
.list-container{
  background-color: white;
  width: 100%;
  border-radius: 4px;
  min-width: 300px;
}
.list-container-title{
  margin: auto;
}
.list-container-header{
  padding: 20px 30px 20px 30px;
}
.list-container-header .text-input input{
  min-width: 200px;
}
.list-container-empty{
  padding-bottom: 42px;
}
.list-container-empty-image{
  text-align: center;
}
.list-container-empty-image img{
  width: 250px;
  height: 250px;
  margin: 30px;
}
.list-container-fields{
  padding: 18px 32px;
  border-bottom: 1px solid #F0F0F0;
  color : #8C8C8C;
}
.list-container-fields-item{
  min-width: 70px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.list-container-search-loader{
  position: relative;
}
.list-container-search-loader svg{
  position: absolute;
  left: 12px;
  top: 18px;
}
.list-container-fields-item svg{
  margin-left: 6px;
}
.list-container-fields-item-order{
  cursor: pointer;
}
.list-container-fields-item-order:hover{
  opacity: 0.8;
}
.list-container-fields .checkbox{
  margin-left: -2px;
  margin-right: 4px;
}
.list-container-menu{
  padding: 26px;
}
.list-container-checkbox{
  margin: -8px -4px -8px 2px;
}
.list-container-status svg, .list-container-status span{
  margin: auto;
}
.list-container-pages{
  padding: 20px 30px 20px 30px;
}
.list-container-pages svg{
  margin: auto 8px;
  cursor: pointer;
}
.list-container-page{
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  margin: 0px 4px;
}
.list-container-page:hover{
  opacity: 0.8;
}
.list-container-page-empty{
  opacity: 0;
}

/* list-dropdown */
.list-dropdown{
  position: relative;
}
.list-dropdown-text{
  cursor: pointer;
}
.list-dropdown-text svg{
  margin-left: 12px;
  display: none;
}
.list-dropdown-text:hover svg{
  display: initial !important;
}
.list-dropdown-text-visible svg{
  display: initial;
}

/* list-icon */
.list-icon{
  min-width: 50px;
  max-width: 50px;
  max-height: 50px;
}
.list-icon-editable{
  cursor: pointer;
}
.list-icon-editable:hover{
  opacity: 0.8;
}
.list-icon img{
  width: 28px;
  height: 28px;
  border-radius: 50px;
  margin-top: -4px;
  margin-bottom: -8px;
}
.list-icon-fallback{
  width: 28px;
  margin: 1px auto;
  font-size: 18px;
}
.list-icon-animation * {
  fill: url(#my-gradient);
}
/* list-input */
.list-input{
  min-width: 50px;
}
.list-input-text{
  cursor: pointer;
}
.list-input-text svg{
  margin-left: 12px;
  cursor: pointer;
}
.list-input-text-button-not-visible svg{
  display: none;
}
.list-input-text-:hover svg{
  display: initial !important;
}
.list-input-text svg:hover{
  opacity: 0.8;
}
.list-input input{
  outline: none;
  width: calc(100% - 30px);
  border-radius: 8px;
  stroke-width: 1;
  font-size: 14px;
  margin: -6px 0px;
  margin-bottom: -10px;
  padding: 4px 8px;
  border: 1px solid #b8b8b8;
}
.list-input input[type="date"]{
  padding: 3px 6px;
}


/* list-item */
.list-item{
  padding: 8px 32px;
  border-top: 1px solid #F0F0F0;
  position: relative;
}
.list-item-large{
  padding: 18px 32px;
}
.list-item-element{
  padding: 0px 6px;
  min-width: 70px;
}
.list-item-alert{
  position: relative;
  color: #8c8c8c;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.list-item-alert svg{
  color: #c7c8c7;
}
.list-item-alert-high{
  font-weight: bold;
  padding: 2px 6px;
  margin: -2px 0px;
  border-radius: 4px;
}
.list-item-alert-low{
  position: absolute;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  bottom: 0px;
  left: -2px;
}
.list-item-alert-message{
  width: 140px;
  font-size: 10px;
  line-height: 12px;
  margin: -3px 0px -3px 12px;
}
.list-item-alert svg{
  margin-right: 4px;
}
.list-item-editable{
  cursor: pointer;
}
.list-item-bullet{
  margin-right: 12px;
}
.list-item-editable:hover{
  background-color: #F9FBFC;
}
.list-item-element-editable{
  min-width: 70px;
  min-height: 20px;
  border-radius: 4px;
}
.list-item-element-editable:hover{
  cursor: text;
}
.list-item-element-context-empty .list-item-element-context{
  opacity: 0;
}
.list-item-element-context-empty .list-item-element-context:hover{
  opacity: 1;
}
.list-item-element-context{
  color: white;
  min-width: 40px;
  border-radius: 10px;
  font-size: 12px;
  padding: 1px 8px;
  background-color: #c7c8c7;
}
.list-item-element-context-classic{
  min-width: 40px;
  border-radius: 4px;
}
.list-item-element-context-empty .list-item-element-context-classic{
  opacity: 0;
}
.list-item-element-context-empty .list-item-element-context-classic:hover{
  opacity: 1;
}
.list-item-element-context-editable svg, .list-item-element-context-classic svg{
  margin: auto;
}
.list-item-element-context-editable:hover, .list-item-element-context-classic:hover{
  cursor: pointer;
}
.list-item-dropdown{
  cursor: context-menu;
  padding: 4px 12px 4px 4px;
  margin: -4px;
  min-width: 32px;
}
.list-item-filtered {
  color: #FFA01A;
}
.list-item-text{
  width: 100%;
}
.list-item-text input{
  font-size: 14px;
  padding: 3px 6px;
  margin: -4px;
  border-radius: 4px;
  border: 1px solid;
  width: calc(100% - 22px);
  border-color: #c7c8c7;
  height: 22px;
}
.list-item-text input:focus{
  outline: none;
}
.list-item-checkbox {
  margin: -8px 0px;
}
.list-item-icon-check svg{
  font-size: 14px !important;
  margin-right: 4px !important;
}
.list-item-icon-check svg{
  margin-left: -18px;
}
.list-item-folder{
  color: #8c8c8c;
  margin-left: 8px;
}
.list-item-delete{
  cursor: pointer;
  padding: 4px;
  margin-left: 30px;
}
.list-item-delete:hover{
  opacity: 0.8;
}
.list-item-delete svg{
  margin: auto;
}
.list-item-icon{
  color: #e8e8e8;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  margin: -2px 4px -6px 0px;
  text-align: center;
}
.list-item-icon svg{
  margin: auto;
  font-size: 18px;
  border-radius: 50%;
}
.list-item-image img{
  height: 24px;
  width: 24px;
  border-radius: 50%;
  margin: -2px 4px -6px 0px;
}
.list-item-image-field img{
  width: auto;
  height: 32px;
  margin: -10px 0px;
  cursor: pointer;
  border-radius: 4px;
}
.list-item-deleted{
  text-decoration: line-through;
}
.list-item-element-context, .list-item-element-context-classic{
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list-item-stars{
  margin: auto 0px;
}
.list-item-stars-hover:hover{
  opacity: 0.6;
}
.list-item-noborder{
  border-top: none;
}
.list-item-nopadding{
  padding-left: 0px;
}
.list-item .selector-display{
  margin: -12px 28px -14px 0px;
}
.list-item .checkbox-container{
  height: auto;
  margin-right: 0px;
  margin-top: 1px;
}
.list-item .checkbox{
  margin-top: -4px;
  margin-bottom: -4px;
}

/* load-container */
.load-container{
  text-align: center;
  margin: auto;
  font-size: 32px;
  padding: 30px;
}

/* loading-bar */
.loading-bar-container{
  margin: 6px 0px;
}
.loading-bar-label{
  min-width: 200px;
}
.loading-bar{
  position: relative;
  background-color: #dcdcdc;
  border-radius: 8px;
  margin: auto;
}
.loading-bar-inside{
  height: 20px;
  border-radius: 8px;
  min-width: 8px;
}
.loading-bar-count{
  position: absolute;
  top: 2px;
  font-size: 12px;
  left: 12px;
  color: white;
}

/* menu item */
.menutop-item{
  padding: 0px 18px;
  cursor: pointer;
  text-align: center;
  height: 100%;
}
.menutop-item:hover, .menutop-item-opacity{
  background-color: #0000001a;
}
.menutop-item-content{
  margin: auto;
}
.menutop-item-content svg{
  font-size: 18px;
}
@media screen and (max-width: 1100px) {
  .menutop-item-dropdown, .invitations-remaining-text{
    display: none;
  }
}
.menutop-item-dropdown svg{
  margin: -1px;
  margin-left: 4px;
}
.menutop-item-content img{
  height: 24px;
  width: 24px;
  border-radius: 4px;
  margin-top: -4px;
}
.menutop-item-dropdown{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 160px;
}

/* modale */
.modal{
  background-color: white;
  border-radius: 8px;
  margin: auto;
  padding: 30px 40px;
  min-width: 400px;
  max-width: 600px;
}
.modal-content{
  max-height: calc(100vh - 200px);
  margin: 20px -40px;
  padding: 0px 40px;
}
.modal-confirm{
  min-width: 360px;
  max-width: 360px;
}
.modal .import-img .context-menu{
  top: -100px;
  right: 0px;
}
.modal p{
  margin-bottom: 22px;
}
.modal-container{
  top: 0px;
  left: 0px;
  position: fixed;
  z-index: 998;
  background-color: #00000040;
  height: 100vh;
  width: 100vw;
  cursor: initial;
}
.modal-scrollable-container-withborder{
  overflow: auto;
  margin-right: -40px;
  border-top: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
  margin-left: -40px;
  padding: 0px 40px;
}
.modal-scrollable-container{
  overflow: auto;
  margin-right: -40px;
}
.modal-image-right{
  text-align: right;
}
.modal-image-right img{
  width: 150px;
  height: 150px;
  margin: -20px;
}
.modal-image-top{
  text-align: center;
}
.modal-image-top img{
  width: 200px;
  height: 200px;
}
.modal-footer{
  margin-top: 32px;
}
.modal-medium{
  max-width: 800px;
}
.modal-large{
  max-height: initial;
  height: calc(100vh - 82px);
  margin-top: 22px;
  max-width: initial;
}
.modal .list-container-inside{
  max-height: 258px;
  overflow-y: auto;
}
.modal.modal-large .list-container-inside{
  max-height: initial;
  overflow-y: initial;
}

/* bouton dans modale */
.modal-button{
  color: #20CA7E;
  border-radius: 4px;
  border: 1px solid #F0F0F0;
  font-size: 14px;
  width: 200px;
  height: 200px;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  background-color: initial;
}
.modal-button svg{
  margin-bottom: 12px;
  font-size: 32px;
  margin: 12px auto;
}
.modal-button svg path{
  fill : #20CA7E !important;
}
.modal-button:hover{
  color: white;
  background-color: #20CA7E;
  border-color: #20CA7E;;
}
.modal-button:hover svg path{
  fill : white !important;
}

/* modale type help */
.modal-help-container{
  top: 0px;
  left: 0px;
  position: fixed;
  z-index: 999;
  background-color: #00000040;
  height: 100vh;
  width: 100vw;
}
.modal-help{
  position: fixed;
  width: 460px;
  top: 72px;
}
.modal-help-left{
  left: -4px;
}
.modal-help-right{
  right: -4px;
}
.modal-help-large .modal-help{
  width: 860px;
}
.modal-help-content-left{
  margin-left: -26px;
}
.modal-help-content-right{
  margin-right: -26px;
}
.modal-help-large .modal-help-content{
  max-height: 740px;
}
.modal-help-content p{
  margin-bottom: 20px;
}
.modal-help .card{
  width: 400px;
}
.modal-help-large .card{
  width: 800px;
}

/* nav */
.nav-dashboard-subheader{
  position: fixed;
  background-color: #e7e7e7;
  width: calc(100vw - 24px);
  z-index: 99;
  right: 0px;
  top: 48px;
  padding: 4px 12px;
  box-shadow: 0px 0px 4px #d4d4d4;
  border-top: 1px solid;
  border-bottom: 1px solid;
  max-height: 66px;
  overflow-y: auto;
}
.nav-focus-action-large{
  margin-left: 0px !important;
  margin-right: 4px !important;
}
.nav-focus-action-large svg{
  margin-right: 6px !important;
}
.nav-wizard{
  margin-top: 72px;
  height: calc(100vh - 72px) !important;
}

/* notification */
.notification, .notification-hovered{
  position: absolute;
  top: -10px;
  right: -8px;
  border: 1px solid;
  font-size: 10px;
  background-color: white;
  padding: 1px 4px;
  border-radius: 10px;
  text-align: center;
}

.notification{
  width: 44px;
}

.notification-hovered{
  opacity: 1;
  width: 16px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.notification-hovered.notification-hidden{
  opacity: 0;
  -webkit-transition: all 1s linear;
  -moz-transition: all 1s linear;
  -o-transition: all 1s linear;
  transition: all 1s linear;
}

.notification:hover{
  background-color: #f7f7f7; 
}

.notification .notification-icon{
  display: none;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.notification.notification-hovered .notification-icon{
  display: initial;
}

.notification .notification-text{
  display: initial;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}


.notification.notification-hovered .notification-text{
  display: none;
}


/* page-empty */
.page-empty{
  width: 400px;
  margin: 40px auto;
  text-align: center;
}
.page-empty img{
  width: auto;
  height: 200px;
  margin: 20px;
}
.page-empty-secondary{
  margin-bottom: 22px;
}
.page-empty-help{
  text-align: initial;
}
.page-empty-help svg{
  margin: auto 20px;
  font-size: 32px;
}

/* page-header */
.page-header{
  margin-bottom: 32px;
}
.page-header-titles{
  min-width: 250px;
}
.page-header-warning{
  margin-top: 12px;
}
.page-header-warning svg{
  margin-right: 6px;
}

/* page de login */
.page-login{
  width : 100%;
  height: calc(100vh - 40px);
  padding : 20px;
  overflow-y: auto;
}

/* page-navigation */
.page-navigation{
  min-width: 620px;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100vw;
  height: 100vh;
}
.page-navigation-contact{
  position: fixed;
  background-color: #f2506e;
  bottom: 30px;
  right: 30px;
  box-shadow: 0px 2px 10px #8a8a8a;
  font-size: 22px;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  z-index: 11;
}
.page-navigation-contact svg{
  margin: auto;
}
.page-navigation-contact:hover{
  opacity: 0.8;
}
.page-navigation-back-superadmin{
  text-decoration: underline;
  margin-right: 22px !important;
}
.page-navigation-back-superadmin:hover{
  opacity: 0.8;
}
.page-navigation-withheader{
  margin-top: 48px;
  height: calc(100vh - 48px);
}
.page-navigation-menutop{
  position : fixed;
  z-index: 9;
  height : 48px;
  box-shadow: 0px 0px 4px #d4d4d4;
  background-color: white;
  width: 100vw;
}
.page-navigation-menutop .button{
  padding: 6px;
  margin: 8px 8px 8px 0px;
}
.page-navigation-menutop .button span{
  font-size: 12px;
  margin: 0px 12px;
}

.page-navigation-footer{
  position : fixed;
  z-index: 9;
  height : 48px;
  box-shadow: 0px 0px 4px #d4d4d4;
  background-color: #F2D600;
  width: 100vw;
  bottom: 0px;
}
.page-navigation-footer span{
  text-decoration: underline;
  cursor: pointer;
}
.page-navigation-footer span:hover{
  opacity: 0.8;
}
.page-navigation-back-disabled img{
  height: auto;
  width: 100px;
  margin: auto;
  margin-left: 24px;
  cursor: pointer;
}
.page-navigation-back-disabled img:hover{
  opacity: 0.8;
}
.page-navigation-back-disabled span{
  margin: auto 18px;
  font-size: 12px;
}
.page-navigation-iframe{
  overflow: hidden;
  height: calc(100vh - 60px);
  margin-right: -20px;
}
.page-navigation-logo{
  width: 36px;
  height: 36px;
  margin: 6px 8px;
  cursor: pointer;
}
.page-navigation-login{
  color: white;
  margin: auto 16px;
  cursor: pointer;
  text-decoration: underline;
}
.page-navigation-back-text{
  margin: auto;
  max-width: 320px;
}
.page-navigation-back-text span{
  font-weight: bold;
}
.page-navigation-logo-lg{
  width: initial;
  filter: brightness(500%);
}
.page-navigation-circle-img{
  max-width: 36px;
  min-width: 36px;
  max-height: 36px;
  min-height: 36px;
  margin: 6px 8px;
  border-radius: 50px;
  cursor: pointer;
}
.page-navigation-circle-img:hover{
  opacity: 0.8;
}
.page-navigation-separator{
  border-right: 1px solid white;
  padding-right: 6px;
  height: 22px;
  margin: auto;
  margin-right: 12px;
}
.page-navigation-unselectable .page-navigation-circle-img{
  cursor: initial;
}
.page-navigation-unselectable .page-navigation-circle-img:hover{
  opacity: 1;
}
.page-navigation-text{
  margin: auto;
}
.page-navigation-text svg{
  font-size: 16px;
  margin-left: 8px;
}
.page-navigation-menuleft{
  min-width: 240px;
  width: 252px;
  padding: 16px 0px;
  margin: 0px 20px;
}
.page-navigation-menuleft-item{
  cursor: pointer;
  padding: 18px 10px;
  border-radius: 8px;
}
.page-navigation-menuleft-item:hover{
  background-color: #F0F0F0;
}
.page-navigation-menuleft-item-active{
  font-weight: 700;
}
.page-navigation-menuleft-item-locked:hover{
  background-color: initial;
}
.page-navigation-children{
  padding: 20px 40px;
}
.page-navigation-scrollable{
  overflow-y: auto;
}
@media screen and (max-width: 800px) {
  .page-navigation-menuleft {
    display: none;
  }
}
.page-navigation-loading{
  text-align: center;
  font-size: 22px;
  margin-top: 32px;
}
.page-navigation-back{
  margin: auto 10px;
  border-radius: 50%;
  height: 36px;
  width: 36px;
  line-height: 36px;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
}
.page-navigation-back:hover{
  background-color: #F0F0F0;
}

/* page status */
.page-status{
  margin: 80px 0px;
  text-align: center;
  width: 100%;
}
.page-status svg{
  font-size: 22px;
  margin-bottom: 8px;
}


/* page wizard */
.page-wizard-back svg{
  font-size: 32px;
  cursor: pointer;
  margin: auto 0px;
  margin-left: 42px;
}
.page-wizard-back svg:hover{
  opacity: 0.6;
}

/* carte question */
.question{
  margin-top: 40px;
  margin-bottom: 40px;
  border-radius: 8px;
  background-color: white;
  width: 320px;
  min-height: 512px;
  position: relative;
}
.question-trigger{
  color: white;
  margin-bottom: 24px;
  width: 210px;
  font-size: 12px;
}
.question-inactive{
  position: absolute;
  background-color: white;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  z-index: 2;
}
.question-empty{
  margin-top: 80px;
  text-align: center;
}
.question-nps{
  border-radius: 50%;
  height: 26px;
  width: 26px;
  color: white;
}
.question-nps-container{
  height: 70px;
}
.question-nps-label{
  font-size: 12px;
  margin: -8px 20px 14px 20px;
}
.question-nps-separator{
  border-bottom: 1px solid;
  margin-bottom: 8px;
  margin-left: 12px;
}
.question-nps-chevron{
  margin: 3px 8px 0px -5px;
  font-size: 11px;
}
.question-nps span{
  margin: auto;
}
.question-open{
  margin: 12px;
  border: 2px dotted #8C8C8C80;
  border-radius: 8px;
  text-align: center;
  font-size: 12px;
}
.question-open svg{
  opacity: 0.5;
  margin-bottom: 6px;
  font-size: 32px;
}
.question-other-language{
  margin: 12px 12px 0px 12px;
}
.question-other-language span{
  cursor: pointer;
  overflow: hidden;
  height: 42px;
}
.question-other-language span:hover{
  opacity: 0.8;
}
.question-numeric-false-help{
  color : white;
  padding: 0px 18px;
  font-size: 12px;
}
.question-headeronly{
  min-height: 20px;
  height: 32px;
  cursor: pointer;
  border-radius: 4px 4px 0px 0px;
}
.question-choice-count{
  margin: 0px 20px;
  font-size: 12px;
  margin-top: -4px;
}
.question-axis-image{
  margin-bottom: 8px;
}
.question-proposition-draggable:hover{
  background-color: #eaeaea80;
  border-radius: 8px;
}
.question-axis-image img{
  width: 65px;
  height: 65px;
  margin-top: 18px;
}
.question-topic{
  text-align: center;
  margin: 8px 0px;
}
.question-separator{
  border-bottom: 2px solid #d7d7d7;
  height: 52px;
  margin: 0px 14px;
}
.question-header{
  min-width: 260px;
  max-width: 400px;
}
.question-secondary{
  font-size: 12px;
  margin-top: 22px;
}
.question-name{
  color: #5c5c5c;
  font-size: 22px;
  text-align: center;
  padding: 0px 14px;
}
.question .select-container{
  margin-bottom: 0px;
}
.question-search{
  margin-left: 14px;
  font-size: 12px;
  cursor: pointer;
}
.question-search:hover{
  opacity: 0.8;
}
.question textarea{
  margin: 12px;
  resize: none;
  height: 150px;
  border: none;
  padding: 4px 8px;
  background-color: #eaeaea;
  border-radius: 8px;
  width: calc(100% - 40px);
}
.question-container{
  margin-top: 40px;
}
.question-fullscreen{
  min-width: 600px;
  width: calc(100vw - 650px);
  margin-left: 46px;
  box-shadow: 8px 0px 12px #868686;
}
.question-params{
  margin: 40px 30px;
  width: 210px;
}
.question-option{
  background-color: #e6e6e6;
  color: white;
  border-radius: 50%;
  min-width: 62px;
  min-height: 62px;
  max-width: 62px;
  max-height: 62px;
  text-align: center;
  margin: auto;
}
.question-option-sm{
  position: relative;
  z-index : 1;
  min-width: 28px;
  min-height: 28px;
  max-width: 28px;
  max-height: 28px;
  line-height: 28px;
}
.question-axis-header{
  text-align: center;
}
.question-option-separator{
  position: absolute;
  width: calc(100% - 12px);
  height: 10px;
  top: 9px;
  right: calc(50% + 6px);
}
.question-option-center{
  margin: auto;
  border: 1px solid white;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  line-height: 42px;
}
.question-option-label{
  margin: 8px 16px;
  font-size: 12px;
}
.question-nspp{
  font-size: 12px;
  text-align: center;
  margin: 22px;
}
.question-nspp-text{
  margin: auto 10px
}
.question-proposition-container{
  margin: 14px;
}
.question-proposition-checkbox{
  margin: 0px 12px 12px 12px;
  font-size: 12px;
}
.question-proposition-circle{
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 50%;
}
.question-fullscreen .question-proposition-container{
  width: 100%;
  margin-right: 52px;
}
.question-fullscreen .question-numeric{
  width: 100%;
  margin: 40px;
  margin-right: 80px;
  max-width: 400px;
}
.question-proposition-order svg{
  font-size: 12px;
}
.question-proposition-action{
  text-align: right;
}
.question-proposition-action svg{
  margin: 2px auto;
  cursor: pointer;
  font-size: 18px;
}
.question-proposition-action svg:hover{
  opacity: 0.8;
}
.question-proposition{
  margin-bottom: 16px;
  font-size: 14px;
  border-radius: 8px;
}
.question-proposition-option{
  border : 1px solid;
  min-width: 14px;
  min-height: 14px;
  max-width: 14px;
  max-height: 14px;
  border-radius: 50%;
  margin-top: 2px;
}
.question-proposition-name{
  margin-left: 6px;
}
.question-notopic svg{
  font-size: 32px;
  color: #dedede;
  margin: 10px;
  margin-top: 32px;
}
.question-proposition-container textarea{
  width: calc(100% - 78px);
  margin : 0px;
  margin-left: 6px;
  font-size : 14px;
  text-align : initial;
  height: 52px;
  font-size: 12px;
}
.question-action{
  z-index: 2;
  position: absolute;
  background-color: white;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  text-align: center;
  font-size: 16px;
  line-height: 32px;
  box-shadow: 1px 1px 4px #8c8c8c;
}
.question-action:hover{
  background-color:#e4e4e4;
}
.question-edited{
  border-radius: 4px;
  color: white;
  font-size: 12px;
  margin-bottom: 48px;
}
.question-edited span{
  margin: auto 0px;
}
.question-edited-dots{
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 6px;
  margin-left: 10px;
}
/** input type range **/
.range{
  border-radius: 8px;
  appearance: auto;
  height: 22px;
  cursor: ew-resize;
  accent-color: #d8d8d8;
}

/** regrouping */
.regrouping{
  border: 1px solid #111c2b;
  padding: 4px 8px;
  border-radius: 8px
}
.regrouping-attributes{
  padding : 3px
}

/** range */
.track-0{
  background:red;
}

.track-1{
  background:#ddd;
}

.track-2{
  background:green;
}

/** saving */
.saving{
  font-size: 12px;
  margin: 6px;
  margin-right: 22px;
} 
.saving svg{
  margin-right: 6px;
}

/* scroller div */
.scroller{
  overflow-x: hidden;
  overflow-y: auto
}

.scroller-modal{
  margin: 0px -40px;
}

.scroller-shadow{
  position: sticky;
  height: 20px;
  width: 100%;
  z-index: 10;
}

.scroller-shadow-bottom{
  bottom: 0;
  margin-top: -20px;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.2))
}

.scroller-shadow-top{
  top: 0;
  margin-bottom: -20px;
  background: linear-gradient(rgba(0, 0, 0, 0.2), transparent)
}

/* scroll top container*/
.scrolltop{
  position: fixed;
  z-index: 9;
}

/* select : liste déroulante */
.select-container{
  z-index: 1;
  position: relative;
  margin-bottom: 22px;
}
.select-label{
  color: white;
}
.select-help{
  cursor: pointer;
  margin-right: 10px;
  font-size: 18px;
}
.select-value{
  margin: 10px;
  border-radius: 12px;
  padding: 2px 8px;
  color: white;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid white;
}
.select-value svg{
  margin: auto;
  margin-left: 8px;
}
.select-values{
  max-height: 282px;
  overflow-y: auto;
}
.select-container .context-menu{
  width: initial;
  left: 32px;
}


/* sélecteur de carte */
.selector-cards{
  margin-bottom: 28px;
  margin-left: -14px;
}
.selector-card{
  position: relative;
  max-width: 340px;
  min-width: 260px;
  margin: 14px;
}
.selector-card-sm{
  min-width: 220px;
  max-width: 220px;
}
.selector-card .card{
  margin-bottom: 0px;
  height: calc(100% - 40px);
  cursor: pointer;
}
.selector-card .card-title{
  font-size: 14px;
  color: #8C8C8C;
  text-transform: uppercase;
  font-weight: initial;
}
.selector-card img{
  width: 72px;
  height: 72px;
  margin-left: 20px;
}
.selector-card-active-bottom{
  height: 8px;
  border-radius: 0px 0px 4px 4px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
}
.selector-card-info{
  position: absolute;
  right: 18px;
  top: 18px;
  font-size: 18px;
  cursor: pointer;
}
.selector-card-title-sm{
  min-width: 60px;
}
.selector-card-info:hover{
  opacity: 0.8;
}
.selector-card-sm .card-title{
  display: none
}

/* sélecteur de vue */
.selector-display{
  margin: auto;
}
.selector-display-item{
  margin: 4px;
  cursor: pointer;
  padding: 6px;
  font-size: 18px;
  border-radius: 4px;
}
.selector-display-item:hover{
  background-color: #F0F0F0;
}

/* selection d'une image */
.selector-image{
  border: 2px solid #f0f0f0;
  border-radius: 4px;
  width: 72px;
  height: 72px;
  margin: 10px;
  cursor: pointer;
}
.selector-image:hover{
  opacity: 0.8;
}
.selector-image img{
  height: 62px;
  margin: 5px;
  width: 62px;
}
.selector-image-active{
  background-color: #f0f0f0;
}
.selector-image-container{
  margin: 0px -10px 30px -10px
}
.selector-image-label{
  text-align: center;
  padding: 26px 0px;
}

/* onglet (switch) */
.selector-switch-container{
  background-color: #dadada;
  border-radius: 10px;
  padding: 4px;
  margin-bottom: 22px;
}
.selector-switch{
  border-radius: 8px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  padding: 22px 30px;
  width: 300px;
  text-align: center;
}
.selector-switch:hover{
  opacity: 0.8;
}

/* onglets */
.selector-tabs{
  margin-bottom: 18px;
}
.selector-tabs-tab{
  text-transform: uppercase;
  font-weight: 600;
  padding: 20px;
  text-align: center;
  position: relative;
  cursor: pointer;
}
.selector-tabs-tab:hover{
  background-color: #F0F0F0;
}
.selector-tabs-tab-active{
  font-weight: bold;
  padding: 20px 19px;
}
.selector-tabs-tab-active-bottom{
  height: 6px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
}
.selector-tabs-tab-error{
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 14px;
  right: 6px;
}

/* setup-card */
.setup-card-header{
  font-size: 18px;
  border-radius: 8px;
  background-color: white;
}
.setup-card-header svg{
  margin-top: 2px;
}
.setup-card-header-color{
  width: 14px;
  height: 100%;
  position: absolute;
  border-radius: 8px 0px 0px 8px;
}
.setup-card-header-confirmed .setup-card-header-color{
  background-color: #51E899;
}
.setup-card-header-error .setup-card-header-color{
  background-color: #eb5a46;
}
.setup-card-alert-error{
  color: #eb5a46;
  background-color: #eb5a4650;
}
.setup-card-header-locked{
  background-color: #d9d9d9;
  opacity: 0.25
}

.setup-card-header-warning .setup-card-header-color{
  background-color: #F2D600;
}
.setup-card-alert-warning{
  color: #ff9500;
  background-color: #F2D60050;
}
.setup-card-header-visited svg, .setup-card-header-confirmed svg{
  color: #51E899;
}
.setup-card-header-error svg{
  color: #eb5a46;
}
.setup-card-header-warning svg{
  color: #F2D600;
}
.setup-card-help-content, .setup-card-help-content p{
  font-size: 12px;
  margin-bottom: 6px;
}
.setup-card-help-content li{
  margin-bottom: 6px;
}
.setup-card-alert{
  margin-top: 10px;
}
.setup-card-alert
.setup-card-content{
  padding: 0px;
}
.setup-card-content.setup-card-content-expanded{
  transition: all 0.5s cubic-bezier(0.5, -0.005, 0.2, 1);
  -webkit-transition: all 0.5s cubic-bezier(0.5, -0.005, 0.2, 1);
  height: initial;
}
.setup-card-header-subtitle{
  font-size : 14px;
  margin : auto;
  max-width: 300px;
  text-align: end
}

/* stripe */
.stripe-infos{
  background-color: #e8e8e8;
  text-align: center;
  border-radius: 8px;
  padding: 20px;
}
.stripe-infos a{
  text-decoration: underline !important;
}
.stripe-infos a:hover{
  opacity: 0.8;
}
.stripe-infos img{
  width: 200px;
}
.stripe-card-element{
  background-color: #dadada;
  padding: 20px 30px;
  margin: 20px 0px;
  border-radius: 8px;
}

/* switch-language */
.switch-language{
  margin-bottom: 6px;
}
.switch-language span{
  cursor: pointer;
}
.switch-language span:hover{
  opacity: 0.8;
}

/* team-admin */
.team-admins{
  background-color: #F0F0F0;
  min-width: 220px;
  border-radius: 4px;
  padding : 8px 14px 8px 22px;
  cursor: pointer;
  position: relative;
  margin: 8px 16px 8px 0px;
}
.team-admins-color{
  height: 100%;
  width: 8px;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 4px 0px 0px 4px;
}
.team-admins:hover{
  opacity: 0.8;
}
.team-admins-name{
  height: 100%;
}
.team-admins-name span{
  margin: auto 0px;
}
.team-admins-list{
  margin-top: 12px;
}
.team-admins-icon{
  margin: 6px 8px 0px 0px;
}
.team-admins-icon img{
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin: auto;
  background-color: white;
}
.team-admins-icon svg{
  color: #CCCCCC;
  font-size: 32px;
}
.team-admins-icon-plus{
  position: absolute;
  background-color: white;
  font-size: 12px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  text-align: center;
  top: 54px;
  left: 16px;
}

/* team-edit */
.team-edit-container{
  position: relative;
  margin : 18px 0px 18px 0px;
}
.team-edit{
  position: relative;
  box-shadow: 0px 0px 10px #c5c5c5;
  width: 260px;
  height: 56px;
  border-radius: 4px;
  padding : 8px 14px 8px 22px;
  cursor: pointer;
}
.team-edit:hover{
  opacity: 0.8;
}
.team-edit-content svg{
  margin-top: 2px;
}
.team-edit-color{
  height: 100%;
  width: 8px;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 4px 0px 0px 4px;
}
.team-edit-separator{
  width: 52px;
  border-bottom : 1px solid;
}
.team-edit-btn{
  text-decoration: underline;
}
.team-edit-btn:hover{
  opacity: 0.8;
}

/* toggle-button */
.toggle-button{
  min-width: 46px;
  background-color: #8C8C8C;
  color: white;
  height: 26px;
  position: relative;
  border-radius: 22px;
}
.toggle-button-text{
  margin: 6px 12px 0px 38px;
}
.toggle-button-text.toggle-button-active{
  margin: 6px 38px 0px 12px;
}
.toggle-button-circle{
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #00000054;
  border-radius: 50%;
  margin: 3px;
  right: initial;
}
.toggle-button-circle.toggle-button-active{
  left: initial;
  right: 0
}
.toggle-button-circle svg{
  margin: auto;
}

/* toogle-button */
.toogle-button{
  border: 1px solid;
  border-radius: 8px;
  width: 180px;
  min-height: 68px;
  padding: 8px 16px;
}
.toogle-button-text{
  margin: auto;
  text-align: center;
}

/* text input */
.text-input{
  width: 320px;
}
.text-input-error input{
  border-color: #eb5a46 !important;
}
.text-input-search{
  padding: 16px 2px 16px 12px;
}
.text-input-blur-status{
  min-width: 80px;
}
.text-input-blur-status svg{
  margin: auto 18px;
}
.text-input-check{
  margin: auto;
  font-size : 18px;
  margin-right: 8px;
}
.text-input-number{
  width: 220px;
}
.text-input-help{
  color: #bfbfbf;
  margin-top: 6px;
  margin-bottom: 42px;
}
.text-input-fullwidth{
  padding-right: 0px;
  min-width: 260px;
}
.text-input-fullwidth.text-input{
  width: 100%;
}
.text-input-white input{
  background-color: initial;
  border-color: white !important;
  color: white;
}
.text-input-context svg{
  font-size: 12px;
  margin: auto;
}
.text-input input, .text-input .text-input-context, .text-area{
  min-width: 130px;
  min-height: 22px;
  width: calc(100% - 30px);
  border-radius: 8px;
  stroke-width: 1;
  font-size: 14px;
  margin: 4px 0px;
  padding: 10px 14px;
  border: 1px solid #b8b8b8;
}
.text-area{
  resize: vertical;
}
.text-area-size{
  height: 60px;
  width: 700px;
}
.text-input-context-menu{
  position: absolute;
  top: 28px;
}
.text-input .text-input-context{
  cursor: pointer;
  margin-top: 10px;
  border: none;
}
.text-input input:focus{
  outline: none;
}
.text-input-title{
  margin-bottom: 6px;
  margin-top: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.text-input input:disabled {
  background-color: white;
  border: none;
  color : #8c8c8c;
}
.text-input.text-input-white input:disabled{
  background-color: initial;
  color: white;
  border : 1px solid white !important;
}
.text-input-enable{
  position: relative;
}
.text-input-enable-icon{
  position: absolute;
  padding: 16px 14px;
  cursor: pointer;
  right: 0px;
  color: white;
}
.text-input-enable-icon:hover{
  opacity: 0.8;
}
.text-input-search-cancel{
  position: absolute;
  font-size: 22px;
  right: 0px;
  top: 14px;
  cursor: pointer;
}

/* widget */
.widget{
  position: relative;
}

.widget-inactive{
  background-color: #ffffffbf;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 5;
  top: 0px;
  left: 0px;
}
.widget-item{
  z-index: 8;
  z-index: 9999;
  background-color: white;
  border-radius: 8px;
  position: absolute;
  width: 100%;
}
.widget-inactive svg{
  font-size: 32px;
  margin: auto;
}
.widget-tutorial-back{
  background-color: #00000070;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 8;
  overflow: hidden;
}
.widget-tutorial{
  position: absolute;
  z-index: 12;
  color : white;
  border-radius: 8px;
  padding: 12px 18px;
  max-width: 300px;
  top: 0px;
  background-color: #111C2B;
}
.widget-tutorial-left{
  width: 200px;
  left: -242px;
}
.widget-tutorial-right{
  width: 200px;
  right: -242px;
}

/* composant wizard */
.wizard{
  position: fixed;
  width: calc(100vw - 28px);
  top: 0px;
  background-color: white;
  left: 0px;
  height: 72px;
  box-shadow: 0px 0px 4px #d4d4d4;
  min-width: 800px;
  z-index: 9;
  padding: 0px 14px;
}
.wizard-btn{
  position: fixed;
  top: 88px;
  right: 36px;
}
.wizard-btn svg{
  margin: 0px;
  margin-left: 12px;
}
.wizard-img{
  width: 100px;
  margin: auto;
  margin-right: 12px;
  cursor: pointer;
}
.wizard-img:hover{
  opacity: 0.8;
}
.wizard-chevron{
  margin : auto;
  font-size: 10px;
}
.wizard-item{
  font-size: 12px;
  max-width: 114px;
  cursor: pointer;
  margin: auto 2px;
  padding: 6px 12px;
  text-align: center;
  border-radius: 8px;
}
@media screen and (min-width: 801px) {
  .wizard-tooltip{
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  .wizard-item span{
    display: none;
  }
  .wizard-item{
    width: initial;
  }
}
.wizard-item:hover{
  background-color: #e8e8e8;
}

/* wizard-container */
.wizard-container-inside{
  margin: 0px 40px;
  width: 900px;
}


/*

tooltip

*/


.tooltip {
  @apply absolute bg-gray-800 text-white text-sm rounded py-2 px-3 shadow-md opacity-90 transition duration-200 ease-in-out;
}
.has-tooltip:hover .tooltip {
  @apply visible z-50;
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}

.tooltip-arrow.top {
  border-width: 6px 6px 0 6px;
  border-color: #222 transparent transparent transparent;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-arrow.bottom {
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent #222 transparent;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-arrow.left {
  border-width: 6px 6px 6px 0;
  border-color: transparent #222 transparent transparent;
  top: 50%;
  left: 100%;
  transform: translateY(-50%) rotate(180deg); 
}

.tooltip-arrow.right {
  border-width: 6px 0 6px 6px;
  border-color: transparent transparent transparent #222;
  top: 50%;
  right: 100%;
  transform: translateY(-50%) rotate(180deg); 
}
